// import styles
import './index.scss';

// global js
// import Header from './components/header/header.js';
import Nav from './components/nav/nav.js';
import Carousel from './components/carousel/carousel';
import Hero from './components/hero/hero';

// let HeaderEl = document.querySelector('.js-header');
// if (HeaderEl) {
//   const header = new Header();
//   header.init();
// }

let NavEl = document.querySelector('.js-nav');
if (NavEl) {
  const nav = new Nav();
  nav.init();
}

let HeroEl = document.querySelector('.js-sticky');
if (HeroEl) {
  const hero = new Hero();
  hero.init();
}

let CarouselEl = document.querySelector('.js-carousel');
if (CarouselEl) {
  const carousel = new Carousel();
  carousel.init();
}
