class Nav {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');
    this.dom.app = document.querySelector('.js-app');
    this.dom.hamburger = this.dom.el.querySelector('.js-hamburger');
    this.dom.item = document.querySelectorAll('.js-item');
  }

  toggleMenu = () => {
    this.dom.app.classList.toggle('nav-open');
  }

  closeMenu = () => {
    this.dom.app.classList.remove('nav-open');
  }

  registerItems = () => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', (event) => {
        event.preventDefault();

        const targetId = anchor.getAttribute('href').substring(1); // Remove the "#" from the href
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        } else {
          // If the target element is not found, open a new page with the specified URL
          window.location.href = '/' + anchor.getAttribute('href');
        }
      });
    });
  }

  init() {
    this.dom.hamburger.addEventListener('click', this.toggleMenu);
    this.registerItems();

    [...this.dom.item].forEach(el => {
      el.addEventListener('click', this.closeMenu);
    });
  }
}

export default Nav;
