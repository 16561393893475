import { tns } from 'tiny-slider';

class Carousel {
  constructor() {
    this.dom = {};
    this.dom.carousel = document.querySelector('.js-carousel');
  }

  slider = () => {
    this.dom.carousel = tns({
      container: '.js-carousel',
      items: 1.25,
      center: true,
      loop: false,
      swipeAngle: false,
      speed: 400,
      mouseDrag: true,
      nav: false,
      controls: false,
      responsive: {
        768: {
          speed: 800,
          controls: true,
          items: 3.5,
          controlsText: ['', ''],
        }
      }
    });
  }

  init() {
    window.addEventListener('load', this.slider);
  }
}

export default Carousel;
