class Hero {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-sticky');
    this.dom.cta = document.querySelector('.js-cta');
  }

  toggleClass = () => {
    const elementRect = this.dom.cta.getBoundingClientRect();
    const elementYPosition = elementRect.top + window.scrollY;
    const scrollPosition = window.scrollY;
    const elementHeight = this.dom.cta.offsetHeight;

    if (elementYPosition >= scrollPosition + elementHeight) {
      this.dom.el.classList.add('is-visible');
    } else {
      this.dom.el.classList.remove('is-visible');
    }
  }

  init() {
    window.addEventListener('scroll', this.toggleClass);
    window.addEventListener('load', this.toggleClass);
  }
}

export default Hero;
